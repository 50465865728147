/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `Seguro`,
    MENU_MARKETS: `página delantera`,
    MENU_ASSET: `mercado`,
    MENU_PLAN: `activos`,
    MENU_USER_CENTER: `Atender`,
    LOGOUT: `centro personal`,
    LOGIN: `abandonar`
};
