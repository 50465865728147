/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `Sicher`,
    MENU_MARKETS: `Titelseite`,
    MENU_ASSET: `Markt`,
    MENU_PLAN: `Vermögenswerte`,
    MENU_USER_CENTER: `Aufschlag`,
    LOGOUT: `Persönliches Zentrum`,
    LOGIN: `aufhören`
};
