/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Allgemeine Geschäftsbedingungen`,
    TERMS: `Nutzungsbedingungen`,
    PRIVACY: `Datenschutzrichtlinie`,
    COOKIE: `Cookie-Richtlinie`,
    AML: `Richtlinie zur Bekämpfung der Geldwäsche`,
    CONDUCT_CODE: `Verhaltenskodex`,
    CUSTOMER_SERVICE: `Kundendienst`,
    HELP_CENTER: `Hilfezentrum`,
    FEEDBACK: `Rückmeldung`,
    CONTACTS: `kontaktiere uns`,
    CHARGES: `Gebühren`,
    SUPPORT: `Service-Unterstützung`,
    ABOUT_US: `über uns`,
    ANNOUNCEMENTS: `offizielle Aussage`,
    FAQ: `FAQ`,
    DEPOSIT_WITHDRAW_STATUS: `Ein- und Auszahlungsstatus`,
    OFFICIAL_VERIFICATION: `Offizielle Verifizierung`,
    LANGUAGES: `Sprache`,
    SERVICE_EMAIL: `Service-E-Mail`,
    APP_DOWNLOAD: `APP-Download`
};
