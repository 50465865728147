import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Popover } from 'antd';

// utils
import { isSignIn } from '../../utils/user';
import { setLanguageCode } from '../../utils/language';
import { setTradeType } from '../../utils/localData';

// constants
import {
  LANGUAGE_DATAS,
  TRADE_TYPE_SPOT,
  TRADE_TYPE_OPTIONS,
  TRADE_TYPE_CONTRACT,
} from '../../constants';

import { ICONS } from '../../assets';

import L from './languages';

import './style.scss';

const className = 'componentWebHeader';

class WebHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.L = L();
  }

  // private
  onAssetClick = () => {
    if (!isSignIn()) {
      window.location.href='/signin';
      return;
    }

    window.location.href='/asset';
  }

  onServiceClick = () => {
    if (!isSignIn()) {
      window.location.href='/signin';
      return;
    }

    window.location.href='/qt';
  }

  // render
  renderLanguage = () => {
    const languageMenu = (
      <Menu>
        {
          LANGUAGE_DATAS.map((data, index) => 
            <Menu.Item key={`${index}`}>
              <div className={`${className}-link`} onClick={() => { setLanguageCode(data.code); window.location.reload() }}>
                {data.name}
              </div>
            </Menu.Item>
          )
        }
      </Menu>
    );

    return (
      <Dropdown overlay={languageMenu}>
        <img
          className={`${className}-language-icon`} 
          src={ICONS.ICON_LANGUAGE}
        />
      </Dropdown>
    );
  }

  renderTradeTypeMenu = () => {
    return (
      <div>
        {this.renderTradeTypeMenuItem(ICONS.TRADE_SPOT, this.L.SPOT, TRADE_TYPE_SPOT)}
        {this.renderTradeTypeMenuItem(ICONS.TRADE_OPTIONS, this.L.OPTIONS, TRADE_TYPE_OPTIONS)}
        {this.renderTradeTypeMenuItem(ICONS.TRADE_CONTRACT, this.L.CONTRACT, TRADE_TYPE_CONTRACT)}
      </div>
    );
  }

  renderTradeTypeMenuItem = (icon, title, tradeType) => {
    return (
      <div
        className={`${className}-menu-item`}
        onClick={() => {
          setTradeType(tradeType);
          window.location.href='/trade';
        }}
      >
        <img className={`${className}-menu-icon`} src={icon} />
        <div className={`${className}-dropdown-menu-title`}>{title}</div>
      </div>
    );
  }

  render() {
    // 当前菜单
    const menu = window.location.pathname.split('/');

    return (
      <div className={`${className}`}>
        <div className={`${className}-box`}>
          <div className={`${className}-left-box`} >
            <img
              className={`${className}-logo`} 
              src={ICONS.LOGO}
              onClick={() => window.location.href='/'}
            />

            <div
              className={`${className}-menu-title`}
              onClick={() => window.location.href='/'}
            >{this.L.MARKET}</div>

            <Popover content={this.renderTradeTypeMenu()} getPopupContainer={() => document.getElementById('drowdown')}>
              <div
                id="drowdown"
                className={`${className}-dropdown-box`}
                onClick={() => window.location.href='/trade'}
              >
                <div className={`${className}-dropdown-title`}>{this.L.TRADE}</div>
                <img className={`${className}-dropdown-icon`} src={ICONS.TRIANGLE_DOWN} />
              </div>
            </Popover>

            {
              isSignIn() ?
                <div
                  className={`${className}-menu-title`}
                  onClick={() => this.onAssetClick()}
                >{this.L.ASSET}</div>
              : null
            }
            {
              isSignIn() ?
                <div
                  className={`${className}-menu-title ${className}-menu-title${menu[1] === 'me' ? '-checked' : ''}`}
                  onClick={() => window.location.href='/user-center'}
                >{this.L.USER_CENTER}</div>
              : null
            }

          </div>

          {
            isSignIn() ?
            <div className={`${className}-right-box`}>
              {this.renderLanguage()}
            </div>
            :
            <div className={`${className}-right-box`}>
              <div
                className={`${className}-signin-btn`}
                onClick={() => window.location.href='/signin'}
              >{this.L.SIGNIN}</div>

              <div
                className={`${className}-signup-btn`}
                onClick={() => window.location.href='/signup'}
              >{this.L.SIGNUP}</div>

              {this.renderLanguage()}
            </div>
          }

        </div>
      </div>
    );
  }
}

export default withRouter(WebHeader);
