/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `Markt`,
    TRADE: `Handel`,
    SPOT: `Spothandel`,
    OPTIONS: `Optionshandel`,
    CONTRACT: `Vertragshandel`,
    ASSET: `Vermögenswerte`,
    LOAN: `Darlehen`,
    USER_CENTER: `Persönliches Zentrum`,
    SIGNIN: `Anmeldung`,
    SIGNUP: `registrieren`,
    SLOGAN: `VERTRAUENSBITS`
};
