import React, {lazy} from 'react';
import PropTypes from 'prop-types';
import {injectReducer} from '../store/reducers';
import rootStore from '../store';


// --------------  登录 注册 邀请注册  --------------
// 邀请注册
const Invite = lazy(() => import(/* webpackChunkName: "Invite" */ './Auth/Invite'));
// 登录
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ './Auth/SignIn'));
// 注册
const SignUp = lazy(() => import(/* webpackChunkName: "SignUp" */ './Auth/SignUp'));
// 忘记密码
const ForgetPwd = lazy(() => import(/* webpackChunkName: "ForgetPwd" */ './Auth/ForgetPwd'));

// --------------  市场页  --------------
const Market = lazy(() => import(/* webpackChunkName: "Market" */ './Market/Index'));

// --------------  交易页  --------------
const Trade = lazy(() => import(/* webpackChunkName: "Trade" */ './Trade/Index'));

// --------------  资产  --------------
// 资产首页
const Asset = lazy(() => import(/* webpackChunkName: "Asset" */ './Asset/Index'));

// --------------  个人中心  --------------
// 个人中心
const UserCenter = lazy(() => import(/* webpackChunkName: "UserCenter" */ './UserCenter/Index'));
// 我的团队
const MyTeam = lazy(() => import(/* webpackChunkName: "MyTeam" */ './UserCenter/Team'));
// 实名认证
const Kyc = lazy(() => import(/* webpackChunkName: "Kyc" */ './UserCenter/Kyc'));
// 邀请
const Share = lazy(() => import(/* webpackChunkName: "Share" */ './UserCenter/Share'));

// --------------  帮助中心  --------------
// 关于我们
const AboutUs = lazy(() => import(/* webpackChunkName: "AboutUs" */ './HelpCenter/AboutUs'));
// Btc入金引导
const GuideBtc = lazy(() => import(/* webpackChunkName: "GuideBtc" */ './HelpCenter/GuideBtc'));

// No Access
const NoAccess = lazy(() => import(/* webpackChunkName: "NoAccess" */ './NoAccess'));

// 404
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ './PageNotFound'));

const Conponents = {
  // 登录 注册 邀请注册
  Invite,
  SignIn,
  SignUp,
  ForgetPwd,

  // 市场
  Market,

  // 交易页
  Trade,

  // 资产
  Asset,
  GuideBtc,

  // 个人中心
  UserCenter,
  MyTeam,
  Kyc,
  Share,

  // 帮助中心
  AboutUs,

  NoAccess,
  PageNotFound,
};

// 查找reducer，component_name 到 path 的映射
const COMPONENT_PATH_MAPPING = {
  // 登录 注册 邀请注册
  'Invite': 'Auth/Invite',
  'SignIn': 'Auth/SignIn',
  'SignUp': 'Auth/SignUp',
  'ForgetPwd': 'Auth/ForgetPwd',

  // 市场页
  'Market': 'Market/Index',

  // 交易
  'Trade': 'Trade/Index',

  // 资产
  'Asset': 'Asset/Index',
  
  // --------------  我的  --------------
  'UserCenter': 'UserCenter/Index',
  'MyTeam': 'UserCenter/Team',
  'Kyc': 'UserCenter/Kyc',
  'Share': 'UserCenter/Share',

  // 帮助中心
  'AboutUs': 'HelpCenter/AboutUs',
  'GuideBtc': 'HelpCenter/GuideBtc',

  'NoAccess': 'NoAccess',
  'PageNotFound': 'PageNotFound',
}

const AsyncComponent = props => {
  const {componentName} = props;

  import(`./${COMPONENT_PATH_MAPPING[componentName]}/reducer`).then(({default: reducer}) => {
    injectReducer(rootStore, {key: componentName, reducer});
  });

  const Component = Conponents[componentName];

  return <Component {...props} />;
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired
};

AsyncComponent.defaultProps = {};

export default AsyncComponent;
